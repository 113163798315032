<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div class="row mt-4">
			<div class="col-xs-12 col-lg-8 d-flex">
				<div class="h2 text-muted py-1">
					{{ translate('become_distributor') }}
				</div>
			</div>
		</div>
		<template
			v-if="!loading">
			<div class="row pt-1 pb-2 justify-content-center align-items-center">
				<div
					v-for="(item, id) in packsData"
					:key="id"
					:class="['xl'].includes(windowWidth) && Object.keys(packsData).length === 3 ? 'col-lg-4' : 'col-lg-3'"
					:style="{ minWidth: '255px' }"
					class="col-12 mb-2">
					<ul
						class="list-group"
						:style="{ minWidth: '255px' }">
						<li class="list-group-item text-center">
							<img
								:src="item.attributes.packages[packagesStates[item.id]].img"
								:style="{ maxWidth: ['xl', 'lg'].includes(windowWidth) ? '215px' : '300px' }"
								class="img-fluid mx-auto pointer">
							<div v-if="item.attributes.packages.length > 1">
								<b-col
									class="m-0 p-0"
									style="font-size: 0.8rem;">
									<p
										style="display:inline"
										class="mr-1">
										{{ translate('regular_option') }}
									</p>
									<switch-toggle
										v-model="toggleStates[item.id]"
										variant="success"
										pill
										class="pt-1"
										style="transform: scale(0.8);"
										@change="() => handlePackToggle(item.id)" />
									<p
										style="display:inline"
										class="ml-2">
										{{ translate('special_option') }}
									</p>
								</b-col>
							</div>
							<div
								v-if="['sm', 'md'].includes(windowWidth)"
								class="row justify-content-center pt-4">
								<div class="col-auto pr-2 pl-0">
									<h1
										class="text-dark font-weight-bold mb-0 h2">
										{{ translate(item.id + '_pack') }}
									</h1>
								</div>
								<div
									class="col-auto h5"
									style="padding: 0; margin-bottom: 0; margin-block: auto">
									{{ "(" + translate('bvs', { qty: item.attributes.packages[packagesStates[item.id]].bv }) + ")" }}
								</div>
							</div>
							<div
								v-else>
								<div
									class="row justify-content-center"
									:class="item.attributes.packages.length > 1 ? 'pt-2' : 'pt-4 mt-2'">
									<div class="col-auto">
										<h1
											class="text-dark font-weight-bold mb-0 h4">
											{{ translate(item.id + '_pack') }}
										</h1>
									</div>
								</div>
								<div
									class="row justify-content-center">
									<div
										class="col-auto h5"
										style="padding: 0; margin-bottom: 0; margin-block: auto">
										{{ "(" + translate('bvs', { qty: item.attributes.packages[packagesStates[item.id]].bv }) + ")" }}
									</div>
								</div>
							</div>
							<p>
								<a
									class="pointer text-muted font-weight-light"
									@click="formatProducts(item)">
									<i class="fa fa-eye" />
									{{ translate('view_details') }}
								</a>
							</p>
							<div
								class="row justify-content-center">
								<div class="col-auto">
									<p
										v-if="!item.attributes.packages[packagesStates[item.id]].fully_customizable"
										class="text-primary h5"
										style="text-decoration:line-through; text-align: center; opacity: 0.6; margin-bottom: 0">
										{{ item.attributes.packages[packagesStates[item.id]].actual_value }}
									</p>
									<p
										v-else
										class="text-primary h5"
										style="text-align: center; opacity: 0.6; margin-bottom: 0">
										{{ translate('min_amount') }}
									</p>
								</div>
							</div>
							<div
								class="row justify-content-center">
								<div class="col-auto pr-2 pl-0">
									<div
										class="col-auto text-primary h3 font-weight-bold"
										style="padding: 0; margin-bottom: 0">
										{{ item.attributes.packages[packagesStates[item.id]].member_price }}
									</div>
								</div>
								<div
									v-if="item.attributes.packages[packagesStates[item.id]].has_active_promo"
									class="col-auto"
									style="padding: 0; margin-bottom: 0; margin-block: auto">
									<span
										class="badge badge-primary pointer text-uppercase"
										style="font-size:12px; margin-bottom: 0;">
										{{ translate('promo') }}
									</span>
								</div>
							</div>
							<div
								v-if="item.attributes.packages[packagesStates[item.id]].has_active_promo && item.attributes.packages[packagesStates[item.id]].old_member_price"
								class="pb-0">
								<span
									class="text-muted">
									{{ translate('non_pack_price', { amount: item.attributes.packages[packagesStates[item.id]].old_member_price }) }}
								</span>
							</div>
							<div
								v-if="item.attributes.packages[packagesStates[item.id]].importation_fee"
								class="pb-2">
								<div>
									<span
										class="font-weight-bold"
										style="font-size: 16px; color: white;">
										<span class="highlight-green">{{ item.attributes.packages[packagesStates[item.id]].importation_fee }}</span>
									</span>
								</div>
								<div style="margin-top: -5px">
									<span style="font-size: 12px; color: black;">
										{{ translate('importation_fee_notice') }}
									</span>
								</div>
							</div>
							<div
								class="pb-4"
								:class="item.attributes.packages.length > 1 ? '' : 'mb-2'">
								<span
									class="font-weight-bold font-italic highlight-yellow">
									{{ translate('savings', { amount: item.attributes.packages[packagesStates[item.id]].savings }) }}
								</span>
							</div>
							<b-button
								:disabled="validating || !!preparing || showFreeProductsModal"
								variant="primary"
								size="lg"
								block
								class="float-right"
								@click="openProductSelection(item)">
								<i
									v-if="validating && item.id === selectedPack"
									class="fas  fa-spinner fa-spin mr-2" />{{ translate('select') }}
							</b-button>
						</li>
					</ul>
				</div>
			</div>
			<div class="row no-gutters justify-content-center pb-2">
				<button
					v-if="!(specialPackagesCountries.includes($user.details().country.iso_code_2) && $user.details().hide_store) && !onlyMembersCountries.includes($user.details().country.iso_code_2)"
					type="button"
					class="btn btn-link text-muted h6"
					style="text-decoration: underline;"
					:disabled="validating || !!preparing || showFreeProductsModal"
					@click="continueWithNoPack()">
					{{ translate('customize_order') }}
				</button>
			</div>
		</template>
		<li
			v-else
			class="list-group-item w-100 h100 fade-in text-center d-flex justify-content-center align-items-center">
			<div class="row h-100 w-100">
				<div class="col-12">
					<div class="h2 py-3">
						<i class="fa fa-fw fa-spinner fa-pulse" />
						<h4 class="mt-3">
							{{ translate('loading') }}
						</h4>
					</div>
				</div>
			</div>
		</li>
		<extra-products-modal
			:sections-import="selectableProductsOptions"
			:selectable-products.sync="selectableProducts"
			:upgrade-options="upgradeOptions"
			:package-code="selectedPack"
			:is-free-modal="isFullyCustomizedPack"
			@close="closeExtraProductsModal"
			@confirm="confirmationModal" />
		<register-pack-products-modal
			:pack-products="packProducts"
			:pack-id="selectedPack"
			:type="packageName.register"
			@close="showRegisterPackProductsModal = false;" />
		<pre-checkout-modal
			is-register
			:next-discount="maxDiscount | currency('usd', translate)"
			:continue-without-package="continueWithoutPackage"
			@close="!continueWithoutPackage ? showFreeProductsModal = true : null; continueWithoutPackage = false;"
			@cancel="showConfimationModal = false; prepareForStorage()"
			@confirm="showConfimationModal = false; openExtraProductsModal = true" />
		<additional-products-modal
			v-show="!loadingProducts"
			:loading-products="loadingProducts"
			:autoship-products="extraProducts"
			:products="products"
			:discounts="discounts"
			:pack-price="packPrice"
			:open="openExtraProductsModal"
			:title="translate('choose_products_title')"
			:fully-customizable="false"
			@cancel="openExtraProductsModal = false; showConfimationModal = true"
			@confirm="handleConfirm($event)" />
		<additional-products-modal
			v-show="!loadingProducts"
			:loading-products="loadingProducts"
			:autoship-products="extraProducts"
			:products="products"
			:discounts="discounts"
			:pack-volume="packVolume"
			:selected-pack="selectedPack"
			:is-register="true"
			:open="openCustomizableProductsModal"
			:title="translate('choose_pack_products_title')"
			:fully-customizable="true"
			@cancel="openCustomizableProductsModal = false"
			@confirm="handleConfirm($event)" />
	</div>
</template>
<script>
import GENERAL_INFO from '@/util/GeneralInformation';
import RegisterPackProductsModal from '@/components/RegisterPackProductsModal';
import ExtraProductsModal from '@/components/ExtraProductsModal';
import AdditionalProductsModal from '@/components/AdditionalProductsModal';
import DocumentTitle from '@/mixins/DocumentTitle';
import WindowSizes from '@/mixins/WindowSizes';
import {
	PACKAGE_NAME_BY_FLOW,
	HIDE_CUSTOM_ORGANIZATION_COUNTRIES,
	ONLY_TUUN_COUNTRIES,
	MEMBERSHIP_PRICE,
} from '@/settings/Register';
import EventBus from '@/util/eventBus';
import CommonMix from './mixins/Common';
import PackMix from './mixins/Package';
import { Purchase, AdditionalProductsModal as extraProductsMessages } from '@/translations';
import { ONLY_MEMBERS_COUNTRIES } from '@/settings/Country';
import ConfigurableProducts from '@/mixins/ConfigurableProducts';
import Products from '@/util/Products';
import { SORTED_PRODUCT_SKUS as sortList } from '@/settings/Products';
import PreCheckoutModal from '@/views/Layouts/Store/components/PreCheckoutModal.vue';
import { currency } from '@/config/Filters';
import SwitchToggle from '@/components/Switch';

export default {
	name: 'BecomeDistributorPack',
	messages: [Purchase, extraProductsMessages],
	components: {
		PreCheckoutModal,
		RegisterPackProductsModal,
		ExtraProductsModal,
		AdditionalProductsModal,
		SwitchToggle,
	},
	filters: {
		currency,
	},
	mixins: [CommonMix, DocumentTitle, PackMix, WindowSizes, ConfigurableProducts],
	data() {
		return {
			showRegisterPackProductsModal: false,
			showFreeProductsModal: false,
			selectableProductsList: [],
			upgradeOptions: [],
			packs: new GENERAL_INFO(),
			selectedPack: '',
			packProducts: [],
			packageName: PACKAGE_NAME_BY_FLOW,
			specialPackagesCountries: HIDE_CUSTOM_ORGANIZATION_COUNTRIES,
			onlyMembersCountries: ONLY_MEMBERS_COUNTRIES,
			onlyTuunCountries: ONLY_TUUN_COUNTRIES,
			selectedUpgradeOption: null,
			specialPackCodename: null,
			showConfimationModal: false,
			prodData: new Products(),
			extraProducts: {},
			openExtraProductsModal: false,
			openCustomizableProductsModal: false,
			toggleStates: {},
			packagesStates: {},
			additionalProducts: {},
			tempSelectedPack: '',
			continueWithoutPackage: false,
		};
	},
	computed: {
		currentPackData() {
			try {
				return this.packsData.find((pack) => pack.id === this.selectedPack);
			} catch (error) {
				return undefined;
			}
		},
		discounts() {
			return this.currentPackData?.attributes?.discounts ?? [];
		},
		maxDiscount() {
			if (this.discounts.length > 0) {
				return this.discounts[this.discounts.length - 1].discount;
			}
			return 0;
		},
		packPrice() {
			return this.continueWithoutPackage ? MEMBERSHIP_PRICE : (this.currentPackData?.attributes?.packages[this.packagesStates[this.selectedPack]].price ?? 0);
		},
		packVolume() {
			return this.currentPackData?.attributes?.packages[this.packagesStates[this.selectedPack]].bv ?? 0;
		},
		selectableProductsOptions() {
			try {
				return this.selectableProductsList;
			} catch (e) {
				return [];
			}
		},
		packsData() {
			try {
				return this.packs.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		loading() {
			return !!this.packs.data.loading;
		},
		products() {
			try {
				const productsData = [...this.prodData.data.response.data.data]
					.sort((a, b) => {
						const aIndex = sortList.indexOf(a.attributes.sku);
						const bIndex = sortList.indexOf(b.attributes.sku);
						if (aIndex === -1) return 1;
						if (bIndex === -1) return -1;
						return aIndex - bIndex;
					});
				return productsData;
			} catch (error) {
				return [];
			}
		},
		loadingProducts() {
			try {
				return !!this.prodData.data.loading;
			} catch (error) {
				return true;
			}
		},
		isFullyCustomizedPack() {
			// eslint-disable-next-line camelcase
			return this.currentPackData?.attributes?.packages[this.packagesStates[this.selectedPack]].fully_customizable ?? false;
		},
	},
	watch: {
		purchase: {
			deep: true,
			handler() {
				this.showRegisterPackProductsModal = false;
			},
		},
		showRegisterPackProductsModal(newVal) {
			if (newVal) {
				EventBus.$emit('showRegisterPackProductsModal');
			}

			if (!newVal) {
				EventBus.$emit('hideRegisterPackProductsModal');
			}
		},
		showFreeProductsModal(show) {
			EventBus.$emit(show ? 'showFreeProductsModal' : 'hideFreeProductsModal');
		},
		showConfimationModal(show) {
			if (show) {
				EventBus.$emit('show-pre-checkout-modal');
			}
		},
		extraProducts(value) {
			this.createWatchesForProducts(value);
		},
		packsData: {
			handler(newData) {
				// Initialize toggle states when packsData changes
				newData.forEach((item) => {
					this.$set(this.toggleStates, item.id, false);
					this.$set(this.packagesStates, item.id, 0);
				});
			},
			immediate: true, // Run handler immediately on component creation
		},
	},
	async created() {
		this.packs.getRegisterPacks(this.getStoredCountry(), 'register', this.$replicated.replicatedSponsor());
		this.getExtraProducts();
		EventBus.$on('upgradePackageSelected', (upgradeOption) => {
			this.selectedUpgradeOption = upgradeOption;
		});
	},
	methods: {
		confirmationModal() {
			if (!ONLY_MEMBERS_COUNTRIES.includes(this.getRegisterCountry()) > 0 && !this.isFullyCustomizedPack) {
				this.showConfimationModal = true;
			} else {
				const additionalProducts = this.isFullyCustomizedPack ? this.additionalProducts : {};
				this.prepareForStorage(additionalProducts, true);
			}
		},
		closeExtraProductsModal() {
			this.showFreeProductsModal = false;
			// if (this.isFullyCustomizedPack) {
			// 	this.openCustomizableProductsModal = true;
			// }
		},
		handlePackToggle(itemID) {
			this.$set(this.packagesStates, itemID, Number(this.toggleStates[itemID]));
		},
		formatProducts(item) {
			this.selectedPack = item.id;
			this.packProducts = item.attributes.packages[this.packagesStates[item.id]].products_pack;
			this.showRegisterPackProductsModal = true;
		},
		handleConfirm(data) {
			if (this.isFullyCustomizedPack) {
				const item = this.packsData.find((pack) => pack.id === data[1]);
				this.openCustomizableProductsModal = false;
				this.listSelectableProducts(item, item.attributes.packages[this.packagesStates[item.id]].selectable_products.length);
				[this.additionalProducts, this.tempSelectedPack] = data;
			} else {
				this.openExtraProductsModal = false;
				this.showConfimationModal = false;
				this.prepareForStorage(data[0]);
			}
		},
		continueWithNoPack() {
			this.showConfimationModal = true;
			this.continueWithoutPackage = true;
			this.validatePacksStep(false, this.steps[this.$route.name].validation);
		},
		prepareForStorage(additionalProducts = {}, updateSelectedPack = false) {
			const packageCodeName = updateSelectedPack ? this.tempSelectedPack : this.selectedPack;
			this.clearValidationError(true);
			this.preparing = true;
			let payload = {};
			if (!this.continueWithoutPackage) {
				payload.product = {
					package_code_name: packageCodeName,
					is_pack: 1,
					package_option: this.packagesStates[packageCodeName],
					products: additionalProducts,
					is_package_upgrade: this.selectedUpgradeOption !== null ? 1 : 0,
					has_additional_products: Object.keys(additionalProducts).length,
				};
			} else {
				payload.product = {
					package_code_name: '',
					is_pack: 0,
					products: additionalProducts,
				};
			}

			const selectedProducts = this.selectableProductsList.length > 0 ? this.selectableProducts : {};
			payload.product = { selectable_products: selectedProducts, ...payload.product };

			payload = { step: this.steps[this.$route.name].validation, ...payload };
			this.validateStep(payload).then((response) => {
				const { cart_id: cartId } = response.response;
				this.saveSubtotal(this.packPrice);
				this.saveCart(cartId);
				this.saveStep(payload);
			}).catch(() => {}).finally(() => { this.preparing = false; });
		},
		openProductSelection(item) {
			this.selectedPack = item.id;
			if (item.attributes.packages[this.packagesStates[item.id]].fully_customizable) {
				this.openCustomizableProductsModal = true;
			} else {
				this.listSelectableProducts(item, item.attributes.packages[this.packagesStates[item.id]].selectable_products.length);
			}
		},
		listSelectableProducts(item, showModal) {
			this.selectedPack = item.id;
			if (showModal) {
				this.selectableProductsList = item.attributes.packages[this.packagesStates[item.id]].selectable_products;
				this.upgradeOptions = item.attributes.packages[this.packagesStates[item.id]].upgrade_options;
				this.showFreeProductsModal = true;
			} else {
				// this.prepareForStorage();
				this.showConfimationModal = true;
			}
		},
		getExtraProducts() {
			// Using autoship products until other configurations are required
			this.prodData.getProducts(this.getRegisterCountry(), undefined, { isRegister: true }).then(() => {
				const products = this.products.reduce((accumulator, product) => {
					if (!product.attributes.has_configurations) {
						accumulator.push({
							sku: product.attributes.sku,
							code_name: product.attributes.code_name,
							parent_sku: null,
							parent_code_name: null,
						});
					} else {
						Object.keys(this.getProductConfigs(product)).forEach((config) => {
							const configuredProduct = this.getConfiguredProduct(product, config);
							accumulator.push({
								sku: configuredProduct.sku,
								code_name: configuredProduct.code_name,
								parent_sku: product.attributes.sku,
								parent_code_name: product.attributes.code_name,
							});
						});
					}
					return accumulator;
				}, []);
				for (let index = 0; index < products.length; index += 1) {
					const product = products[index];
					const { sku } = product;
					const currentAutoshipProduct = this.extraProducts[sku];
					this.$set(this.extraProducts, sku, {
						...product,
						value: currentAutoshipProduct ? currentAutoshipProduct.value += 0 : 0,
						required: currentAutoshipProduct ? currentAutoshipProduct.required : false,
					});
				}
			});
		},
		createWatchesForProducts(value) {
			Object.keys(value).forEach((item) => {
				this.$watch(() => this.extraProducts[item].required, (newVal) => {
					this.clearValidationError(`${this.currentValidation}.autoship.products`);
					if (newVal) {
						if (this.extraProducts[item].value === 0) {
							this.extraProducts[item].value = 1;
						}
					} else {
						this.extraProducts[item].value = 0;
					}
				});
				this.$watch(() => this.extraProducts[item].value, (newVal) => {
					this.clearValidationError(`${this.currentValidation}.autoship.products`);
					if (newVal > 0) {
						this.extraProducts[item].required = true;
					} else {
						this.extraProducts[item].required = false;
					}
				});
			});
		},
	},
};
</script>
